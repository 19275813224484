<template>
  <vx-card
    :title="
      this.surveyCatalogID
        ? 'Edit ' + (type == 'duplicate' ? 'Duplicate' : '') + ' Survey Catalog'
        : 'Create Survey Catalog'
    "
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.surveyCatalogID && this.type == 'edit'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="this.code" :readonly="true"></vs-input>
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input v-model="name" class="w-full" />
      </div>
    </div> -->

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea
          v-model="description"
          width="100%"
          :readonly="isReadOnly"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From - To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <date-range-picker
          style="min-height: 40px"
          class="w-full"
          ref="picker"
          opens="center"
          :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="false"
          :showWeekNumbers="false"
          :showDropdowns="false"
          :autoApply="true"
          v-model="validity"
          :linkedCalendars="true"
          :readonly="isReadOnly"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ dateFormat(picker.startDate) }} -
            {{ dateFormat(picker.endDate) }}
          </template>
        </date-range-picker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Required</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="radio"
          id="isRequiredTrue"
          value="true"
          v-model="isRequired"
          :readonly="isReadOnly"
        />
        <label for="isRequiredTrue">True</label>

        <input
          type="radio"
          id="isRequiredfalse"
          value="false"
          v-model="isRequired"
          :readonly="isReadOnly"
        />
        <label for="isRequiredfalse">False</label>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>One Time</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          type="radio"
          id="true"
          value="true"
          v-model="isOneTime"
          :readonly="isReadOnly"
        />
        <label for="true">True</label>

        <input
          type="radio"
          id="false"
          value="false"
          v-model="isOneTime"
          :readonly="isReadOnly"
        />
        <label for="false">False</label>
      </div>
    </div>

    <!-- <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="(isOneTime && isOneTime != 'false') || isOneTime == 'true'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Must Fill</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input type="radio" id="true1" value="true" v-model="isMustFill" />
        <label for="true1">True</label>

        <input type="radio" id="false1" value="false" v-model="isMustFill" />
        <label for="false1">False</label>
      </div>
    </div> -->

    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid From</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validFrom"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validFrom')">{{
          errors.first("validFrom")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Valid To</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="date"
          :inline="false"
          v-model="validTo"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('validTo')">{{
          errors.first("validTo")
        }}</span>
      </div>
    </div> -->
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <table
          :data="this.surveyCatalogQuestions"
          width="100%"
          class="vs-table vs-table--tbody-table"
        >
          <thead class="vs-table--thead">
            <tr>
              <th width="5%">No</th>
              <th width="35%">Question</th>
              <th width="15%">Answer Type</th>
              <th width="25%">Survey Answer Option</th>
              <th width="5%" style="text-align: center">
                Required <br />
                <input type="checkbox" v-model="isRequiredQuestion" />
              </th>
              <th width="5%"></th>
              <th width="10%"></th>
            </tr>
          </thead>
          <draggable :list="surveyCatalogQuestions" tag="tbody">
            <tr
              v-for="(dt, index) in surveyCatalogQuestions"
              :key="index"
              style="padding-bottom;: 5px"
            >
              <td
                class="td vs-table--td"
                width="5%"
                style="padding-right: 10px"
              >
                {{ index + 1 }}
              </td>
              <td
                class="td vs-table--td"
                width="35%"
                style="padding-right: 10px"
              >
                <vs-input
                  v-model="surveyCatalogQuestions[index].value"
                  class="w-full"
                  :disabled="isReadOnly"
                />
              </td>
              <td
                class="td vs-table--td"
                width="21%"
                style="padding-right: 10px"
              >
                <v-select
                  :filterable="true"
                  :options="answerTypes"
                  v-model="surveyCatalogQuestions[index].type"
                  :disabled="isReadOnly"
                  @input="
                    (val) => {
                      onChangeAnswerType(val, index);
                    }
                  "
                />
              </td>
              <td class="td vs-table--td" width="25%">
                <v-select
                  v-if="
                    surveyCatalogQuestions[index].type == 'Single Choice' ||
                    surveyCatalogQuestions[index].type == 'Multiple Choice'
                  "
                  :filterable="false"
                  :options="optionSurveyAnswer"
                  v-model="surveyCatalogQuestions[index].survey_answer"
                  :disabled="isReadOnly"
                  @input="
                    (val) => {
                      onChangeSurveyAnswer(val, index);
                    }
                  "
                  @search="
                    (search, loading) => {
                      onSearchSurveyAnswer(search, loading);
                    }
                  "
                />
              </td>
              <td class="td vs-table--td" width="5%" style="text-align: center">
                <input
                  type="checkbox"
                  :disabled="isReadOnly"
                  v-model="surveyCatalogQuestions[index].is_required"
                />
              </td>
              <td class="td vs-table--td" width="5%" style="text-align: center">
                <arrow-up-down-bold :size="15" />
              </td>
              <td
                class="td vs-table--td"
                width="10%"
                style="text-align: center"
              >
                <div class="vx-input-group flex" v-if="!isReadOnly">
                  <vs-button
                    v-if="surveyCatalogQuestions.length > 1"
                    @click.stop="removeRow(index)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Row"
                  />

                  <vs-button
                    v-if="index == surveyCatalogQuestions.length - 1"
                    @click.stop="addRow()"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Row"
                  />
                </div>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button class="mr-3 mb-2" @click="handleSubmit" v-if="!isReadOnly && this.surveyCatalogID ? hasPermission('edit') : hasPermission('create')">
            >Submit</vs-button
          >
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import vSelect from "vue-select";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/survey-catalog",
        code: "",
        name: "-",
        description: "",
        validFrom: null,
        validTo: null,
        validity: {
          startDate: null,
          endDate: null,
        },
        surveyCatalogQuestions: [],
        surveyCatalogID: null,
        type: "",
        answerTypes: [
          "Single Choice",
          "Multiple Choice",
          "Text",
          "Number Integer",
          "Number Decimal",
          "Date",
          "Datetime",
          "Image",
          "Time",
        ],
        optionSurveyAnswer: [],
        isRequiredQuestion: true,
        isRequired: true,
        isOneTime: true,
        isMustFill: false,
        isReadOnly: false,
      };
    },
    handleBack() {
      this.$router.push({
        name: "survey-catalog",
      });
    },
    handleSubmit() {
      if (!this.name) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Name is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (!this.validity.startDate || !this.validity.endDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid From - To is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      if (this.surveyCatalogQuestions.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Question is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let cek = this.surveyCatalogQuestions.filter((d) => {
        return d.value == "";
      });
      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Question is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let cekAnswerType = this.surveyCatalogQuestions.filter((d) => {
        return d.type == "";
      });

      if (cekAnswerType.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Answer Type is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let cekAnswerOption = this.surveyCatalogQuestions.filter((d) => {
        return (
          (d.type == "Single Choice" || d.type == "Multiple Choice") &&
          !d.survey_answer
        );
      });

      if (cekAnswerOption.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Answer option is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      let body = {
        name: this.name,
        description: this.description,
        is_required:
          (this.isRequired && this.isRequired != "false") ||
          this.isRequired == "true"
            ? true
            : false,
        is_one_time:
          (this.isOneTime && this.isOneTime != "false") ||
          this.isOneTime == "true"
            ? true
            : false,
        is_must_fill:
          (this.isMustFill && this.isMustFill != "false") ||
          this.isMustFill == "true"
            ? true
            : false,
        valid_from: this.validity.startDate
          ? moment(this.validity.startDate).format("YYYY-MM-DD")
          : null,
        valid_to: this.validity.endDate
          ? moment(this.validity.endDate).format("YYYY-MM-DD")
          : null,
        questions: this.surveyCatalogQuestions,
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          let res = null;
          if (this.surveyCatalogID && this.type == "edit") {
            res = this.$http.put(
              this.baseUrl + "/" + this.surveyCatalogID + "/update",
              body
            );
          } else {
            res = this.$http.post(this.baseUrl + "/create", body);
          }

          res
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    getSurveyCatalog() {
      if (this.surveyCatalogID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.surveyCatalogID + "/edit")
          .then((resp) => {
            if (resp.code == 200) {
              this.code = resp.data.code;
              this.name = resp.data.name;
              this.description = resp.data.description;
              this.isRequired = resp.data.is_required;
              this.isOneTime = resp.data.is_one_time;
              this.isMustFill = resp.data.is_must_fill;
              this.validity.startDate = new Date(resp.data.valid_from);
              this.validity.endDate = new Date(resp.data.valid_to);
              this.surveyCatalogQuestions = resp.data.questions;
              this.isReadOnly = resp.data.is_read_only;
            }
            this.$vs.loading.close();
          });
      } else {
      }
    },
    addRow() {
      this.surveyCatalogQuestions.push({
        survey_catalog_question_id: null,
        survey_answer_id: null,
        survey_answer: null,
        value: "",
        type: "",
        is_required: true,
      });
    },
    removeRow(index) {
      this.surveyCatalogQuestions.splice(index, 1);
    },
    onChangeAnswerType(val, index) {
      if (val != "Single Choice" || val != "Multiple Choice") {
        this.surveyCatalogQuestions[index].survey_answer_id = null;
        this.surveyCatalogQuestions[index].survey_answer = null;
      }
    },
    onChangeSurveyAnswer(val, index) {
      if (val) {
        this.surveyCatalogQuestions[index].survey_answer_id = val.id;
      } else {
        this.surveyCatalogQuestions[index].survey_answer_id = null;
      }
    },
    onChangeRequired(val, index) {
      console.log(val, index);
    },
    onSearchSurveyAnswer(search, loading) {
      if (search.length) {
        loading(true);
        this.searcSurveyAnswer(loading, search, this);
      }
    },
    searcSurveyAnswer: _.debounce((loading, search, t) => {
      t.$http
        .get(t.baseUrl + "/survey-answer", {
          params: {
            length: 10,
            search: search.trim(),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                // return (x.label = x.code + " " + x.name);
                return (x.label = x.code);
              });
              t.optionSurveyAnswer = resp.data.records;
            } else {
              t.optionSurveyAnswer = [];
            }
            loading(false);
          } else {
            t.surveyAnswerOptions = [];
            loading(false);
          }
        });
    }, 350),
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch("user/getPermissions", "survey-catalog");
    this.surveyCatalogID = this.$route.params.id;
    this.type = this.$route.params.type;
    if (!this.surveyCatalogID) {
      this.surveyCatalogQuestions = [
        {
          survey_catalog_question_id: null,
          survey_answer_id: null,
          survey_answer: null,
          value: "",
          type: "",
          is_required: true,
        },
      ];
    } else {
      this.getSurveyCatalog();
    }
  },
  watch: {
    surveyCatalogQuestions: {
      handler: function (val) {
        console.log(val);
      },
      deep: true,
    },
    isOneTime(val) {
      if (!val || val == "false") {
        this.isMustFill = false;
      }
    },
    isRequiredQuestion(val) {
      this.surveyCatalogQuestions.forEach((el) => {
        if (el.is_required != val) {
          el.is_required = val;
        }
      });
    },
  },
};
</script>
